
.fl-r-t,
.fl-t-r,
.fl-l-t,
.fl-t-l,
.fl-l-t-co,
.fl-t-l-co,
.fl-co-l-t,
.fl-co-t-l,
.fl-l-co-t,
.fl-t-co-l,
.fl-l-b-co,
.fl-co-l-b,
.fl-co-b-l,
.fl-b-l-co,
.fl-l-co,
.fl-co-l,
.fl-t,
.fl-c-t,
.fl-t-c,
.fl-l-c-co,
.fl-c-l-co,
.fl-co-l-c,
.fl-co-c-l,
.fl-l-co-c,
.fl-sb-t,
.fl-c-co-l {
    align-items: flex-start;
}

.fl-sb,
.fl-sb-t,
.fl-sb-co,
.fl-co-sb {
    justify-content: space-between;
}

.fl-st {
    justify-content: stretch;
}

.fl-co-st,
.fl-st-co {
    align-items: stretch;
}

.fl-r-t-co,
.fl-t-r-co,
.fl-co-r-t,
.fl-co-t-r,
.fl-r-co-t,
.fl-t-co-r,
.fl-r-b,
.fl-b-r,
.fl-r-b-co,
.fl-b-r-co,
.fl-co-r-b,
.fl-co-b-r,
.fl-r-co-b,
.fl-b-co-r,
.fl-r-co,
.fl-co-r,
.fl-l-b,
.fl-b-l,
.fl-b,
.fl-c-b,
.fl-b-c,
.fl-r-c-co,
.fl-c-r-co,
.fl-co-r-c,
.fl-co-c-r,
.fl-r-co-c,
.fl-c-co-r {
    align-items: flex-end;
}

.fl-l,
.fl-c,
.fl-c-co,
.fl-co-c,
.fl-l-c,
.fl-c-l,
.fl-r-c,
.fl-c-r,
.fl-c-t-co,
.fl-t-c-co,
.fl-co-c-t,
.fl-co-t-c,
.fl-c-co-t,
.fl-t-co-c,
.fl-c-b-co,
.fl-b-c-co,
.fl-co-c-b,
.fl-co-b-c,
.fl-c-co-b,
.fl-b-co-c,
.fl-sb {
    align-items: center;
}

.fl-r-t-co,
.fl-t-r-co,
.fl-co-r-t,
.fl-co-t-r,
.fl-r-co-t,
.fl-t-co-r,
.fl-l-t,
.fl-t-l,
.fl-l-t-co,
.fl-t-l-co,
.fl-co-l-t,
.fl-co-t-l,
.fl-l-co-t,
.fl-t-co-l,
.fl-l-b,
.fl-b-l,
.fl-l,
.fl-t-co,
.fl-co-t,
.fl-l-c,
.fl-c-l,
.fl-c-t-co,
.fl-t-c-co,
.fl-co-c-t,
.fl-co-t-c,
.fl-c-co-t,
.fl-t-co-c {
    justify-content: flex-start;
}

.fl-r-t,
.fl-t-r,
.fl-r-b,
.fl-b-r,
.fl-r-b-co,
.fl-b-r-co,
.fl-co-r-b,
.fl-co-b-r,
.fl-r-co-b,
.fl-b-co-r,
.fl-r,
.fl-l-b-co,
.fl-co-l-b,
.fl-co-b-l,
.fl-b-l-co,
.fl-b-co,
.fl-co-b,
.fl-r-c,
.fl-c-r,
.fl-c-b-co,
.fl-b-c-co,
.fl-co-c-b,
.fl-co-b-c,
.fl-c-co-b,
.fl-b-co-c {
    justify-content: flex-end;
}

.fl-c,
.fl-c-co,
.fl-co-c,
.fl-c-t,
.fl-t-c,
.fl-c-b,
.fl-b-c,
.fl-l-c-co,
.fl-c-l-co,
.fl-co-l-c,
.fl-co-c-l,
.fl-l-co-c,
.fl-c-co-l,
.fl-r-c-co,
.fl-c-r-co,
.fl-co-r-c,
.fl-co-c-r,
.fl-r-co-c,
.fl-c-co-r {
    justify-content: center;
}

.fl-r-t-co,
.fl-t-r-co,
.fl-co-r-t,
.fl-co-t-r,
.fl-r-co-t,
.fl-t-co-r,
.fl-r-b-co,
.fl-b-r-co,
.fl-co-r-b,
.fl-co-b-r,
.fl-r-co-b,
.fl-b-co-r,
.fl-r-co,
.fl-co-r,
.fl-l-t-co,
.fl-t-l-co,
.fl-co-l-t,
.fl-co-t-l,
.fl-l-co-t,
.fl-t-co-l,
.fl-l-b-co,
.fl-co-l-b,
.fl-co-b-l,
.fl-b-l-co,
.fl-l-co,
.fl-co-l,
.fl-t-co,
.fl-co-t,
.fl-b-co,
.fl-co-b,
.fl-c-co,
.fl-co-c,
.fl-c-t-co,
.fl-t-c-co,
.fl-co-c-t,
.fl-co-t-c,
.fl-c-co-t,
.fl-t-co-c,
.fl-c-b-co,
.fl-b-c-co,
.fl-co-c-b,
.fl-co-b-c,
.fl-c-co-b,
.fl-b-co-c,
.fl-l-c-co,
.fl-c-l-co,
.fl-co-l-c,
.fl-co-c-l,
.fl-l-co-c,
.fl-c-co-l,
.fl-r-c-co,
.fl-c-r-co,
.fl-co-r-c,
.fl-co-c-r,
.fl-r-co-c,
.fl-c-co-r,
.fl-sb-co,
.fl-co-sb,
.fl-co-st,
.fl-st-co,
.fl-co {
    display: flex;
    flex-direction: column;
}

.fl-r-t,
.fl-t-r,
.fl-r-b,
.fl-b-r,
.fl-r,
.fl-l-t,
.fl-t-l,
.fl-l-b,
.fl-b-l,
.fl-l,
.fl-t,
.fl-b,
.fl-c,
.fl-c-t,
.fl-t-c,
.fl-c-b,
.fl-b-c,
.fl-l-c,
.fl-c-l,
.fl-r-c,
.fl-c-r,
.fl-sb,
.fl-sb-t,
.fl-st,
.fl {
    display: flex;
    flex-direction: row;
}

.fl-w {
    flex-wrap: wrap;
}

.fl-ss {
    justify-self: center;
    align-self: center;
}