:root {
    --ff-text: "Poppins", sans-serif;
    --ff-head: "roc-grotesk-wide", sans-serif;
    --ff-wide: roc-grotesk-extrawide, sans-serif;
    --color-accent: #4949df;
    --color-body: rgb(0, 0, 0);
    --color-pale: rgba(0, 0, 0, 0.48);
    --color-back: #faf0eb;
    --color-back-hover: #fff5f1;
    --section-gap: 11vh;
    --topgap: 110px;
    --color-back-dark: #f9ece6;
    --color-head: var(--color-back);
    --border-color: hsl(20deg, 10%, 63%);
    --border: 1px solid var(--border-color);
    --color-body-light: #a56342;
    --sides: 50px;
    --max-w: 1200px;
    --navheight: 70px;
    --scrolled-navheight: 50px;
    --topadjust: 0;
    --gap: 40px;
    --gap-small: 20px;
    --transition: 0.3s ease-out;
    --mobile: none;
    --desktop: flex;
    --sidebar: 50vw;
}

*, *:before, *:after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

html, body {
    font-size: 15px;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    color: inherit;
    padding: 0;
}

ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
}

body ._mobile {
    display: none;
}

.m_icon {
    height: 100%;
    width: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-right: var(--border);
}

.about {
    margin-top: var(--gap);
}

.nametitle {
    margin: 0 0 var(--gap) 0;
}

body {
    background-color: var(--color-back);
    overflow: overlay;
    min-width: 250px;
}

body, input, textarea, select, button {
    font-synthesis: none;
    -moz-font-feature-settings: "kern";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    direction: ltr;
    text-align: left;
    font-family: var(--ff-text);
    font-weight: 400;
    color: var(--color-body);
}

.b-r {
    border-right: var(--border);
}

.b-l {
    border-left: var(--border);
}

.main_wrapper {
    /* max-width: var(--max-w); */
    /* margin: auto; */
    width: 100%;
    /* padding: calc(var(--sides) * 2) 0; */
}

main {
    min-height: 100vh;
}

main.cv_page {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

h1, h2, h3 {
    font-family: var(--ff-head);
    font-weight: 400;
    margin: 0;
    padding: 0;
    font-size: 1rem;
}

.head_name h1 {
    font-size: 150%;
    padding: 0 0 0 var(--sides);
    padding-top: var(--topadjust);
    white-space: nowrap;
}

.head_cv {
    font-family: var(--ff-wide);
    font-size: 0.9rem;
    letter-spacing: 0.6px;
    z-index: 2;
    white-space: nowrap;
    padding: 0 var(--sides);
    padding-top: var(--topadjust);
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

section {
    position: relative;
}

section#section_top {
    padding-top: var(--topgap);
}

.anchor {
    position: absolute;
    top: calc(-1 * (var(--scrolled-navheight) + -2px));
}

.anchor#top {
    top: 0;
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 1.5rem;
}

p {
    line-height: 170%;
    color: var(--color-body);
    margin: 0;
    font-weight: 300;
    max-width: 500px;
    font-size: 1rem;
}

._accent {
    color: var(--color-accent);
}

.nametitle > * {
    font-size: 2.6rem;
    line-height: 100%;
    /* white-space: nowrap; */
}

.name {
    margin-bottom: 12px;
}

header.header > * {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

header.header {
    position: fixed;
    width: 100%;
    height: var(--navheight);
    top: 0;
    left: 0;
    z-index: 2;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    border-bottom: var(--border);
    background-color: var(--color-back);
    -webkit-transition: height 0.2s ease-out;
    -o-transition: height 0.2s ease-out;
    transition: height 0.2s ease-out;
}

._scrolled {
    --navheight: var(--scrolled-navheight);
}

.head_left {
    z-index: 1;
}

.head_right {
    z-index: 5;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    border-left: var(--border);
    max-width: var(--sidebar);
}

.head_left {
    font-size: 1rem;
    /* flex-grow: 1; */
    z-index: 3;
    position: relative;
    -webkit-transform: translateX(calc(-100% - 2px));
    -ms-transform: translateX(calc(-100% - 2px));
    transform: translateX(calc(-100% - 2px));
    background-color: var(--color-head);
    -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.58, 0.03, 0.27, 1.01) 0.4s;
    transition: -webkit-transform 0.6s cubic-bezier(0.58, 0.03, 0.27, 1.01) 0.4s;
    -o-transition: transform 0.6s cubic-bezier(0.58, 0.03, 0.27, 1.01) 0.4s;
    transition: transform 0.6s cubic-bezier(0.58, 0.03, 0.27, 1.01) 0.4s;
    transition: transform 0.6s cubic-bezier(0.58, 0.03, 0.27, 1.01) 0.4s, -webkit-transform 0.6s cubic-bezier(0.58, 0.03, 0.27, 1.01) 0.4s;
}

.head_name {
    cursor: pointer;
}

.loading .head_left {
    -webkit-transition-duration: 0s;
    -o-transition-duration: 0s;
    transition-duration: 0s;
}

._scrolled .head_left {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}

.nav_wrapper {
    height: calc(100% + 1px);
    position: absolute;
    border: 0;
    left: 100%;
    --width: 200px;
    border-bottom: var(--border);
    width: var(--width);
    background-color: var(--color-head);
}

.nav_wrapper > * {
    z-index: 1;
}

body .nav {
    position: absolute;
    top: 100%;
    left: 0;
    width: calc(var(--width) - 1px);
    border: var(--border);
    border-top: 0;
    background: var(--color-back);
    margin-top: 1px;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0.45, 0.72, 1.09);
    transition: -webkit-transform 0.3s cubic-bezier(0, 0.45, 0.72, 1.09);
    -o-transition: transform 0.3s cubic-bezier(0, 0.45, 0.72, 1.09);
    transition: transform 0.3s cubic-bezier(0, 0.45, 0.72, 1.09);
    transition: transform 0.3s cubic-bezier(0, 0.45, 0.72, 1.09), -webkit-transform 0.3s cubic-bezier(0, 0.45, 0.72, 1.09);
    z-index: -1;
    display: var(--mobile);
}

.nav.open {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}

.nav_wrapper.is_home .nav {
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
}

.nav li {
    border-bottom: var(--border);
    width: 100%;
}

.nav .link {
    --height: 49px;
    min-height: var(--height);
    height: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding: 13px 23px;
    cursor: pointer;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    font-family: var(--ff-head);
    font-size: 1rem;
}

.nav .link:hover {
    background-color: var(--color-back-hover);
}

.nav li:last-child {
    border: 0;
}

.nav .link.active {
    pointer-events: none;
    background-color: var(--color-back-dark);
}

.nav_wrapper .nav_trigger {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 60px;
    height: 100%;
    cursor: pointer;
    z-index: 3;
    border: var(--border);
    border-width: 0 1px;
    background-color: var(--color-back);
    -webkit-transition: width var(--transition), border-color var(--transition);
    -o-transition: width var(--transition), border-color var(--transition);
    transition: width var(--transition), border-color var(--transition);
    display: var(--mobile);
}

.nav_wrapper.is_home .nav_trigger {
    width: 0;
    border-color: transparent;
    margin-right: -2px;
}

.mt_part {
    --trigger-w: 1px;
    width: 100%;
    border-bottom: 1px solid black;
    height: 1px;
    top: 0;
    position: absolute;
    left: 0;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transition: opacity var(--transition), -webkit-transform var(--transition);
    transition: opacity var(--transition), -webkit-transform var(--transition);
    -o-transition: transform var(--transition), opacity var(--transition);
    transition: transform var(--transition), opacity var(--transition);
    transition: transform var(--transition), opacity var(--transition), -webkit-transform var(--transition);
}

.mt_parts {
    height: 15px;
    width: 20px;
    position: relative;
    -webkit-transition: -webkit-transform 0.7s cubic-bezier(0, 0, 0.01, 1.02);
    transition: -webkit-transform 0.7s cubic-bezier(0, 0, 0.01, 1.02);
    -o-transition: transform 0.7s cubic-bezier(0, 0, 0.01, 1.02);
    transition: transform 0.7s cubic-bezier(0, 0, 0.01, 1.02);
    transition: transform 0.7s cubic-bezier(0, 0, 0.01, 1.02), -webkit-transform 0.7s cubic-bezier(0, 0, 0.01, 1.02);
}

.mt_parts.open {
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.mtp_2 {
    top: calc(50% - var(--trigger-w));
}

.mtp_3 {
    top: calc(100% - var(--trigger-w));
}

.mt_parts.open .mtp_1 {
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
}

.mt_parts.open .mtp_2:not(.mtp_22) {
    -webkit-transform: rotate(50deg);
    -ms-transform: rotate(50deg);
    transform: rotate(50deg);
}

.mt_parts.open .mtp_22 {
    -webkit-transform: rotate(-50deg);
    -ms-transform: rotate(-50deg);
    transform: rotate(-50deg);
}

.mt_parts.open .mtp_3 {
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
}

.svg {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.main_right {
    fill: var(--color-accent);
    padding: var(--topgap) var(--sides) 0 var(--sides);
    border-left: var(--border);
    position: fixed;
    width: var(--sidebar);
    height: 100%;
    right: 0;
    top: 0;
}

.main_left {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding-right: var(--sides);
    padding: 0 var(--sides) var(--sides);
    max-width: calc(100% - var(--sidebar));
}

.main_right .svg {
    height: 250px;
}

.home_link {
    font-family: var(--ff-head);
    font-size: 2.2rem;
    font-weight: 300;
    height: 60px;
    --color: var(--color-body);
    color: var(--color);
    -webkit-transition: color var(--transition);
    -o-transition: color var(--transition);
    transition: color var(--transition);
}

.home_link .svg {
    margin-left: 12px;
    -webkit-transition: -webkit-transform var(--transition);
    transition: -webkit-transform var(--transition);
    -o-transition: transform var(--transition);
    transition: transform var(--transition);
    transition: transform var(--transition), -webkit-transform var(--transition);
}

.home_link svg {
    width: 22px;
}

.home_link:hover .svg {
    -webkit-transform: translateX(8px);
    -ms-transform: translateX(8px);
    transform: translateX(8px);
}

.home_link:hover {
    --color: var(--color-accent);
}

.home_nav {
    -webkit-box-pack: stretch;
    -ms-flex-pack: stretch;
    justify-content: stretch;
}

.home_nav li {
    width: auto;
    min-width: 200px;
}

.home_link:hover span:after {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}

.hl_text {
    position: relative;
    line-height: 94%;
}

.hl_text:after {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    top: 100%;
    left: 0;
    -webkit-transform-origin: left;
    -ms-transform-origin: left;
    transform-origin: left;
    background-color: var(--color);
    -webkit-transform: scaleX(0.7);
    -ms-transform: scaleX(0.7);
    transform: scaleX(0.7);
    -webkit-transition: background-color var(--transition), -webkit-transform var(--transition);
    transition: background-color var(--transition), -webkit-transform var(--transition);
    -o-transition: transform var(--transition), background-color var(--transition);
    transition: transform var(--transition), background-color var(--transition);
    transition: transform var(--transition), background-color var(--transition), -webkit-transform var(--transition);
}

.title_bar {
    width: 100%;
    font-family: var(--ff-wide);
    padding-bottom: var(--gap);
}

.me_image {
    -ms-flex-item-align: end;
    align-self: flex-end;
    fill: var(--color-accent);
    margin-top: calc(var(--sides) * 2);
}

.me_image svg {
    width: 230px;
}

.info {
    padding: 0 0 0 var(--gap);
    /* flex-grow: 1; */
}

.textlink {
    font-size: 0.8rem;
    text-transform: uppercase;
    --color: var(--color-body);
    color: var(--color);
    letter-spacing: 1.3px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px 0;
    -webkit-transition: opacity 0.15s ease-out;
    -o-transition: opacity 0.15s ease-out;
    transition: opacity 0.15s ease-out;
    white-space: nowrap;
}

.textlink:hover {
    opacity: 0.7;
}

.tl_prefix {
    font-size: 10px;
    background: var(--color-body);
    padding: 3px 5px 2px;
    font-family: var(--ff-head);
    border-radius: 5px;
    border-bottom-right-radius: 0;
    font-weight: 500;
    letter-spacing: 0.6px;
    margin-right: 21px;
    position: relative;
    --color: var(--color-back);
    color: var(--color);
}

.tl_prefix:after {
    content: "";
    --size: 8px;
    width: var(--size);
    height: var(--size);
    /* border: var(--border); */
    position: absolute;
    left: calc(100% - 1px);
    background: var(--color-body);
    top: calc(100% - 1px);
    border-radius: calc(var(--size) / 2);
    border-top-left-radius: 0;
    -webkit-transition: -webkit-transform var(--transition);
    transition: -webkit-transform var(--transition);
    -o-transition: transform var(--transition);
    transition: transform var(--transition);
    transition: transform var(--transition), -webkit-transform var(--transition);
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
    border-color: var(--color);
}

.ap_cv._scrolled .tl_prefix:after {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
}

.textlink svg {
    width: 15px;
}

.textlink .svg {
    margin-left: 8px;
    fill: var(--color);
    -webkit-transition: -webkit-transform var(--transition);
    transition: -webkit-transform var(--transition);
    -o-transition: transform var(--transition);
    transition: transform var(--transition);
    transition: transform var(--transition), -webkit-transform var(--transition);
}

.textlink:hover .svg {
    -webkit-transform: translateX(6px);
    -ms-transform: translateX(6px);
    transform: translateX(6px);
}

section:not(section:first-child) {
    padding-top: var(--section-gap);
    margin-top: var(--section-gap);
    border-top: var(--border);
}

.section_title {
    font-size: 1.5rem;
    font-family: var(--ff-wide);
    color: var(--color-accent);
    margin-bottom: var(--gap);
}

.se_part {
    margin-bottom: 40px;
}

.se_part:last-child {
    margin-bottom: 0;
}

.se_part p {
    font-size: 1rem;
}

.sepa_head {
    font-size: 103%;
    letter-spacing: 0.3px;
    font-weight: 500;
}

.sepa_sub {
    margin: 0;
    font-weight: 400;
}
.sepa_sublet {
    margin: 0;
    margin-top: 5px;
    font-size: 15px;
}
.sepa_list {
    padding-left: 24px;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 300;
}
.sepa_list li {
    list-style: disc;
}
body .desk_nav {
    display: var(--desktop);
    height: 100%;
    margin-left: var(--sides);
}

.desk_nav li {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.link_d {
    white-space: nowrap;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 16px;
    font-size: 1rem;
    font-family: var(--ff-head);
}

.link_d:hover {
    color: var(--color-accent);
}

.link_d span {
    border-bottom: 2px solid transparent;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: -1px;
}

.link_d:first-child {
    padding-left: 0;
}

.link_d.active span {
    color: var(--color-accent);
    border-color: var(--color-accent);
}

.head_name h1 {
    font-size: 1.5rem;
    padding-right: 20px;
}

.m_icon svg {
    width: 20px;
    fill: transparent;
    stroke: var(--color-body);
    stroke-width: 1;
}

.top_icons {
    height: 40px;
    margin-bottom: var(--gap);
    /* margin-left: -14px; */
}

.top_icons .m_icon {
    border: 0;
    width: 70px;
    justify-content: flex-start;
}

.top_icons .m_icon svg {
    width: 30px;
    stroke-width: 0.7px;
}

.top_icons .m_icon:hover svg {
    stroke: var(--color-accent);
}

.skill {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 7px;
    /* padding-bottom: 7px; */
    width: 100%;
    /* border-bottom: 1px solid rgb(0 0 0 / 12%); */
}

p.sk_text {
    line-height: 150%;
}

.sk_bar {
    width: 140px;
    height: 5px;
    margin-right: var(--gap-small);
    border: 1px solid var(--color-accent);
    position: relative;
}

.sk_bar span {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: var(--color-accent);
}

.appball {
    --color: var(--color-accent);
    --width: 33px;
    width: var(--width);
    min-width: var(--width);
    height: var(--width);
    min-height: var(--width);
    border: 2px solid var(--color);
    color: var(--color);
    padding: 0 1px 0 0;
    font-family: var(--ff-head);
    font-weight: 500;
    font-size: 0.9rem;
    margin-right: 10px;
    border-radius: 4px;
}

.appballs {
    margin-bottom: var(--gap-small);
}

.appicon {
    --size: 33px;
    padding: 8px;
    width: var(--size);
    padding-bottom: 5px;
    fill: var(--color-accent);
}

@media screen and (max-width: 1200px) {
    :root {
        --desktop: none;
        --mobile: flex;
        --gap: 20px;
        --sides: 16px;
        --navheight: 40px;
        --scrolled-navheight: 40px;
        --section-gap: 5vh;
        --topgap: 68px;
    }

    .sk_bar {
        width: 100%;
        margin-top: 3px;
    }

    .skill {
        flex-direction: column-reverse;
        align-items: flex-start;
        margin-bottom: 20px;
    }

    .top_icons .m_icon {
        width: 50px;
    }

    .top_icons .m_icon svg {
        width: 25px;
    }

    .me_image svg {
        width: 200px;
    }

    .head_cv {
        font-size: 0.7rem;
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
    }

    p {
        font-size: 0.95rem;
    }

    p.sk_text {
        font-size: 0.8rem;
    }

    .nametitle > * {
        font-size: 2.1rem;
    }

    .home_link {
        font-size: 1.6rem;
        height: 44px;
    }

    .home_link svg {
        width: 16px;
    }

    .hl_text:after {
        height: 1px;
    }

    .section_title {
        font-size: 1.1rem;
    }

    .head_name h1 {
        font-size: 1.1rem;
        padding-right: 20px;
    }

    .tl_prefix {
        display: none;
    }
}

@media screen and (max-width: 700px) {
    body ._mobile {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .home_link {
        font-size: 1.2rem;
        height: 30px;
    }

    .sk_bar {
    }

    .nametitle > * {
        font-size: 1.8rem;
    }

    body ._no_mobile {
        display: none !important;
    }

    .main_left {
        max-width: 100%;
    }

    .head_right {
        border: 0;
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
    }

    .hl_text:after {
        display: none;
    }

    .hl_text {
        font-weight: 400;
    }

    .nav .link {
        --height: 30px;
        padding: 8px 14px;
    }

    .nav_wrapper {
        --width: calc(100vw - 65px);
    }

    body .nav {
        max-width: 200px;
    }

    .info {
        border-width: 0 1px;
        padding: 0;
    }

    .head_left {
        -webkit-transition: 0.3s cubic-bezier(0, 0.31, 0.58, 1);
        -o-transition: 0.3s cubic-bezier(0, 0.31, 0.58, 1);
        transition: 0.3s cubic-bezier(0, 0.31, 0.58, 1);
    }
}
